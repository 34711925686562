<template>
    <v-autocomplete v-model="model" :items="items" :loading="isLoading" :search-input.sync="search" hide-no-data
        hide-selected clearable item-text="name" item-value="id" label="项目" placeholder="输入关键字" return-object
        :disabled="disabled" @change="change">
        <template v-slot:append-item>
            <div v-intersect="endIntersect" />
        </template>
    </v-autocomplete>

</template>

<script>
import { project_list } from '@/api/project'
export default {
    props: {
        disabled: {
            type: Boolean,
            required: true
        },
        project_id: {
            type: Number,
           
        },
        project_name: {
            type: String,
            
        },
        
    },
    data: () => ({
        nameLimit: 60,
        model:null,
        entries: [],
        isLoading: false,        
        search: null,
        oldsearch: null,
        page: 1,
        finished: false,
    }),
    mounted() {
        this.updateModel()
    },
    computed: {        
        items() {
            if (!this.entries.length) {
                return [this.model]
            }
            return this.entries.map(entry => {
                const name = entry.name.length > this.nameLimit
                    ? entry.name.slice(0, this.nameLimit) + '...'
                    : entry.name
                //const id = entry.id
                return Object.assign({}, entry, { name })
            })
        },

    },

    watch: {       
        async search(val) {            
            if (this.search == this.oldsearch) return
            this.oldsearch = this.search            
            this.page = 1
            this.finished = false
            this.entries = []
            this.getProjectList()
        },
        project_id(val) {
            this.updateModel()
        
        },
    },
    methods: {
        async getProjectList() {
            this.isLoading = true
            let data = {
                page: this.page,
                keyword: this.search,
            }
            let res = await project_list(data)

            if (res.length === 0) {
                this.finished = true
            }
            else {
                this.entries.push(...res)
                this.page++
            }
            this.isLoading = false
        },
        endIntersect() {
            if (this.finished) return
            this.getProjectList()

        },
        change() {
            if(this.model!=null){
            this.$emit('projectChange', this.model)
            }            
        },
        updateModel(){
            if(this.project_id!=null){
            this.model = {
                id: this.project_id,
                name: this.project_name,
            }
            this.search=this.project_name
            this.oldsearch=this.project_name
            }
        }
    },
}
</script>