import request from '@/utils/request'

export  function sale_order_list(params) {
    return request({
      url: '/api/sale_order/sales',
      method: 'get',
      params,
    })
  }

  export  function sale_order(id) {
    return request({
      url: '/api/sale_order/sale/'+id,
      method: 'get',
    })
  }