<template>
  <div v-wechat-title="$route.meta.title">   
  <keep-alive>
    <router-view v-if="$route.meta.keepAlive"></router-view>
  </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view> 
  </div>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
// document.body.addEventListener('touchmove', function (e) {
//   e.preventDefault(); //阻止默认的处理方式(阻止下拉滑动的效果)
// }, {passive: false}); //passive 参数不能省略，用来兼容ios和android
</script>
