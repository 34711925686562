<template >
   <div>
      <v-navigation-drawer v-model="drawer" fixed app>
         <v-toolbar flat dark color="primary">
            <v-list>
               <v-list-item>
                  <v-list-item-title class="title">
                     销售
                  </v-list-item-title>
               </v-list-item>
            </v-list>
         </v-toolbar>
         <v-list nav>
            <v-list-item-group active-class="deep-purple--text text--accent-4">
               

               <v-list-item @click="drawer = false;" to="/">
                  <v-list-item-icon>
                     <v-icon>mdi-cards-variant </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>销售订单卡片</v-list-item-title>
               </v-list-item>

               <v-list-item @click="drawer = false;" to="/home">
                  <v-list-item-icon>
                     <v-icon>mdi-format-list-numbered</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>销售订单列表</v-list-item-title>
               </v-list-item>

               <v-list-item @click="drawer = false;" to="/sale_expansion">
                  <v-list-item-icon>
                     <v-icon>mdi-cards-variant </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>销售订单扩展</v-list-item-title>
               </v-list-item>

               <v-list-item @click="loginout">
                  <v-list-item-icon>
                     <v-icon>mdi-login</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>退出</v-list-item-title>
               </v-list-item>
            </v-list-item-group>
         </v-list>
      </v-navigation-drawer>

      <v-app-bar color="deep-purple" dark app>
         <v-app-bar-nav-icon @click.stop="drawer = !drawer;"></v-app-bar-nav-icon>
         <v-toolbar-title>销售订单</v-toolbar-title>
         <v-spacer></v-spacer>

      <v-btn icon to="/sale_detail">
        <v-icon>mdi-plus-thick</v-icon>
      </v-btn>

      <v-btn icon @click="search">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      </v-app-bar>

   </div>
</template>

      <script>
      import { logout } from '@/api/user.js'
      export default {
         data: () => ({
            drawer: false,
            group: [],
         }),
         props: {
            source: String,
         },
         methods: {
            loginout() {
               logout().then(() => {
                  localStorage.removeItem('token')
                  this.$router.push('/login')
               })
            
            },
            search(){
               this.$emit('openSearch')
            }
         },
      };
      </script>

