<template>
  <v-dialog v-model="show"  @click:outside="close" scrollable max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h6">销售订单搜索</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field label="关键字" v-model="condition.keyword" clearable clear-icon="mdi-close-circle">
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-select v-model="condition.state" :items="items" label="订单状态" multiple small-chips deletable-chips
                chips-label="name"></v-select>
            </v-col>

          </v-row>
        </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray" elevation="3" rounded @click="clear">
          清 空
        </v-btn>

        <v-btn color="primary" elevation="3" rounded @click="save">
          搜 索
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      items: [
        { text: '报价单', value: 'draft' },
        // {text: '报价已发送', value: 'sent'},
        { text: '部门审批', value: 'bumen' },
        { text: '销售订单', value: 'sale' },
        // {text: '已锁定', value: 'done'},
        // {text: '已取消', value: 'cancel'},
      ],
      condition: {
        keyword: '',
        state: [],
      },

    }
  },


  methods: {
    close() {
      this.$emit('update:show', false)

    },
    clear() {
      this.condition = {
        keyword: '',
        state: [],
      }
    },

    save() {
      this.$emit('update:show', false)
      this.$emit('search', this.condition)
    }
  }

}
</script>