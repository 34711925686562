<template>  
  <v-app>
    <SaleTopBar />
    <v-main>
      <v-container fluid>
      <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished" load-offset="100">
      <v-list three-line>
        <template v-for="(item,index) in items" >                
          
          <v-list-item :key="item.name">          

            <v-list-item-content>
              <v-list-item-title>{{item.project}}（{{item.name}}）</v-list-item-title>
              <v-list-item-subtitle>客户：{{item.partner}}</v-list-item-subtitle>
              <v-list-item-subtitle>执行公司：{{item.company}}</v-list-item-subtitle>
              <v-list-item-subtitle>合计：{{item.amount_total}} | 已收款：{{item.amount_total_ys}} | 已开票：{{item.amount_total_ykp}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
           
            <v-progress-circular
      :value="100"
      color="green"
      :size="20"
      v-if="item.state=='sale'"
    ></v-progress-circular>
    <v-progress-circular
      :value="40"
      color="amber"
      :size="20"
      v-if="item.state!='sale'"
    ></v-progress-circular>
                        
            </v-list-item-icon>
          </v-list-item>
            <v-divider
            v-if="index < items.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list>
       
</vue-loadmore>
     
      </v-container>
    </v-main>
    <BottomBar />

  </v-app>
</template>

<script>
import BottomBar from '../components/BottomBar.vue'
import SaleTopBar from '../components/SaleTopBar.vue'
import { sale_order_list } from '@/api/sale'

export default {
  name: 'Home',

  components: {
    BottomBar,
    SaleTopBar,
  },
  data: () => ({
    items: [],
    page:1,
    finished:false,
  }),
  mounted() {
    this.getSaleOrderList()
  },
  methods: {
    getSaleOrderList() {
     let data = {
        page:this.page,             
      }
      sale_order_list(data).then(res => {       
       
        this.items.push(...res)
        
        this.page++
      })
    },
    onRefresh(done) {
      this.page=1
      this.items=[]
      this.getSaleOrderList()
      done()
    },
    onLoad(done) {      
      this.getSaleOrderList()
      done()
    },
  },
}
</script>