<template>
    <v-app>
        <SaleTopBar @openSearch="openSearch"/>        
        <v-main>
            <SaleSearch :show.sync="showSearch" @search="search"></SaleSearch>
            <v-container fluid>
                
                <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished" load-offset="100">
                    <v-row dense>
                        <v-col cols="12">
                            <v-chip
      v-if="condition.keyword||condition.state.length"
      class="ma-2"
      close
      color="primary"
      text-color="white"
      @click:close="clearCondition"
    >
    <v-avatar left><v-icon small>mdi-tag</v-icon></v-avatar>
     <span v-if="condition.keyword" class="text-wrap">
      "{{condition.keyword}}"</span>
      <template v-if="!condition.keyword">
      <span v-for="(item,index) in stateItems" :key="index" v-if="condition.state.indexOf(item.value)!=-1" class="ml-2 mr-2 text-wrap">
     
      {{item.text}}
      </span>
      </template>
    </v-chip>
                        </v-col>
                        <v-col cols="12" lg="3" v-for="(item, index) in items" :key="index">
                            <v-card elevation="7" shaped>
                                <v-card-title class="text-truncate" style="display: block">

                                    <v-icon color="primary" v-if="item.state == 'sale'">mdi-check</v-icon>
                                    <v-icon color="amber" v-if="item.state != 'sale'">mdi-progress-clock</v-icon>

                                    {{ item.project }}
                                </v-card-title>
                                <v-card-subtitle>订单号：{{ item.name }}</v-card-subtitle>

                                <v-card-text>
                                    <div>客户：{{ item.partner }}</div>
                                    <div>执行公司：{{ item.company }}</div>
                                    <div>销售员：{{ item.saler }}</div>
                                    <div>

                                        <v-chip class="mr-1 mt-2" color="primary" text-color="white" small>

                                            合计￥{{ item.amount_total }}
                                        </v-chip>
                                        <v-chip class="mr-1 mt-2" color="teal" text-color="white" small>

                                            已收款￥{{ item.amount_total }}
                                        </v-chip>
                                        <v-chip class="mt-2" color="pink" text-color="white" small>

                                            已开票￥{{ item.amount_total }}
                                        </v-chip>
                                    </div>
                                </v-card-text>

                                <v-card-actions>

                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" elevation="1" rounded text
                                        :to="{ path: '/sale_detail', query: { 'id': item.id } }">
                                        <v-icon dark>
                                            mdi-pencil
                                        </v-icon> 详情
                                    </v-btn>
                                    <v-btn color="pink" elevation="1" rounded text>
                                        <v-icon dark>
                                            mdi-delete
                                        </v-icon> 删除
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                            <v-spacer></v-spacer>
                        </v-col>
                    </v-row>
                </vue-loadmore>
            </v-container>
            
        </v-main>
        <BottomBar />
        <BackToTop />

    </v-app>
</template>

<script>
import BottomBar from '../components/BottomBar.vue'
import SaleTopBar from '../components/SaleTopBar.vue'
import BackToTop from '@/components/BackToTop.vue'
import SaleSearch from '../components/SaleSearch.vue'
import { sale_order_list } from '@/api/sale'

export default {
    name: 'SaleCards',

    components: {
        BottomBar,
        SaleTopBar,
        BackToTop,
        SaleSearch,
    },
    data: () => ({
        items: [],
        page: 1,
        finished: false,
        scrollTop: 0,
        showSearch:false,
        condition: {
            keyword: '',
            state:[],
        },
        stateItems: [
        { text: '报价单', value: 'draft' },
        // {text: '报价已发送', value: 'sent'},
        { text: '部门审批', value: 'bumen' },
        { text: '销售订单', value: 'sale' },
        // {text: '已锁定', value: 'done'},
        // {text: '已取消', value: 'cancel'},
      ],
    }),
    created() {        
        this.$vloading.show()     
        this.getSaleOrderList()
    },
    

    beforeRouteLeave(to, from, next) {
        // 离开组件时保存滚动位置
        // 注意, 此时需调用路由守卫`beforeRouterLeave`而非生命周期钩子`deactivated`
        // 因为, 此时利用`deactivated`获取的 DOM 信息已经是新页面得了
        if (to.name=='sale_detail'){
            this.scrollTop = document.documentElement.scrollTop
        }else{
            this.scrollTop =0
        }        
        next();
    },
    activated() {
        // 进入该组件后读取数据变量设置滚动位置
        // 注意, 此处由页面是否具有 DTD (如: `<!DOCTYPE html>`), 决定具体选择, 详见参考资料
        document.documentElement.scrollTop = this.scrollTop
        // document.body.scrollTop = this.scrollTop;
    },
    beforeRouteEnter(to, from, next) {           
        next(vm=>{
            if(from.name=='login'){
                vm.getSaleOrderList()
            }
        });
    },
    
    methods: {
        async getSaleOrderList() {                   
            let data = {
                page: this.page,
                keyword:this.condition.keyword,
                state:this.condition.state,
            }
            let res=await sale_order_list(data)
            this.$vloading.hide()            
            if (res.length==0){
                this.finished=true
            }else{
                this.items.push(...res)
                this.page++
            }
        },
        onRefresh(done) {
            this.page = 1
            this.items = []
            this.getSaleOrderList()
            done()
        },
        onLoad(done) {
            this.getSaleOrderList()
            done()
        },
        openSearch() {            
            this.showSearch = !this.showSearch
           
        },
        search(data){
            this.condition=data
            this.finished=false
            this.page = 1
            this.items = []
            this.$vloading.show() 
            this.getSaleOrderList()
            this.scrollTop = 0
            document.documentElement.scrollTop = this.scrollTop
        },
        clearCondition(){
            this.condition={
                keyword:'',
                state:[],
            }
            this.finished=false
            this.page = 1
            this.items = []
            this.getSaleOrderList()
            this.scrollTop = 0
            document.documentElement.scrollTop = this.scrollTop
        }


    },
}
</script>