<template>
   <v-app id="inspire">
      <v-content>
         <v-container fluid fill-height>

            <v-layout align-center justify-center>

               <v-flex xs12 sm8 md4>
                  <v-img :src="require('../assets/logo1.png')" contain height="70" class="mb-16 mt-n16" />
                  <v-card class="elevation-12 ml-3 mr-3" shaped>
                     <v-toolbar dark color="primary">
                        <v-toolbar-title>用户登录</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form ref="form">
                           <v-text-field prepend-icon="person" name="login" label="用户名" type="text"
                              v-model.trim="form.login" :rules="rules.login"></v-text-field>
                           <v-text-field id="password" prepend-icon="lock" name="password" label="密码" type="password"
                              v-model.trim="form.password" :rules="rules.password"></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :loading="loading" color="primary" elevation="3" rounded @click="handleLogin">登 录</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-content>
   </v-app>
</template>

<script>
import { login } from '@/api/user.js'
export default {
   name: 'Login',
   props: {
      source: String,
   },
   data() {
      return {
         form: {
            login: '',
            password: '',
         },
         rules: {
            login: [
               v => !!v || '用户名不能为空',
               v => (v && v.length > 2) || '用户名长度不能小于2位',
            ],
            password: [
               v => !!v || '密码不能为空',
               v => (v && v.length > 2) || '密码长度不能小于2位',
            ],
         },
         loading: false,

      }
   },
   methods: {
      async handleLogin() {
         if (this.$refs.form.validate()) {
            this.loading=true
            try {
               await login(this.form).then(res => {
                  let token=res.session.sid
                  if(token){
                     localStorage.setItem('token',token)
                     this.$router.push('/')
                  }
               })
            } finally {
               this.loading=false
            }

         }

      },
   },
};
</script>

<style>
</style>
