<template>  
  <v-app>
    <SaleTopBar />
    <v-main>
      <v-container fluid>
      
       
      
     
    
       <v-row justify="center">
        <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished" load-offset="100">
        
        
        
    <v-expansion-panels    v-model="panel">
      <v-expansion-panel
         v-for="(item,index) in items"
        :key="index"
      >
        <v-expansion-panel-header>{{item.project}}（{{item.name}}）</v-expansion-panel-header>
       <v-expansion-panel-content>
           <div>客户：{{item.partner}}</div>
      
      <p>执行公司：{{item.company}}</p>
      <div class="text--primary">
        合计：{{item.amount_total}} | 已收款：{{item.amount_total_ys}} | 已开票：{{item.amount_total_ykp}}
      </div>
     
        <div class="text--primary mt-5" >
            
                                    <v-btn color="teal accent-4" outlined rounded text>
                                        编辑
                                    </v-btn>
        </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    </vue-loadmore>
  </v-row>
    
     
      </v-container>
    </v-main>
    <BottomBar />

  </v-app>
</template>

<script>
import BottomBar from '../components/BottomBar.vue'
import SaleTopBar from '../components/SaleTopBar.vue'
import { sale_order_list } from '@/api/sale'

export default {
  name: 'SaleExpansion',

  components: {
    BottomBar,
    SaleTopBar,
  },
  data: () => ({
    items: [],
    page:1,
    finished:false,
    panel: [],
  }),
  mounted() {
    this.getSaleOrderList()
  },
  methods: {
    getSaleOrderList() {
     let data = {
        page:this.page,             
      }
      sale_order_list(data).then(res => {       
       
        this.items.push(...res)
        
        this.page++
      })
    },
    onRefresh(done) {
      this.page=1
      this.items=[]
      this.getSaleOrderList()
      done()
    },
    onLoad(done) {      
      this.getSaleOrderList()
      done()
    },
     all () {
        console.log(this.items)
        this.panel = [...this.items.keys()].map((k, i) => i)
        console.log(this.panel)
      },
      // Reset the panel
      none () {
        this.panel = []
      },
  },
}
</script>