import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import SaleCardsView from '../views/SaleCardsView.vue'
import SaleExpansionView from '../views/SaleExpansion.vue'
import SaleDetailView from '../views/SaleDetailView.vue'
import TestView from '../views/TestView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      title: '销售订单列表',     
    },
  },
  {
    path: '/',
    name: 'sale_cards',
    component: SaleCardsView,
    meta: {
      keepAlive: true,
      title: '销售订单卡片列表',     
    },
  },
  {
    path: '/sale_detail',
    name: 'sale_detail',
    component: SaleDetailView,
    meta: {
      title: '销售订单详情',     
    },
  },
  {
    path: '/sale_expansion',
    name: 'sale_expansion',
    component: SaleExpansionView,
    meta: {
      title: '销售订单扩展',     
    },
  },
  {
    path: '/test',
    name: 'test',
    component: TestView,
    meta: {
      title: '测试',     
    },
  },
  {
    path: '/login',
    name: 'login',    
    component: LoginView,
    meta: {
      title: '登录',     
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
