<template>

    <div class="d-flex flex-row flex-wrap align-center mt-4">
      <template v-for="(item, index) in options">
      
    <v-avatar :key="index"
      :color="selectIndex >= index ? 'primary' : 'grey lighten-2'"
      size="20" class="mr-1">
      <span class="white--text text-h7">{{index+1}}</span>      
    </v-avatar>
     
               <span style="min-width: 40px;max-width:80px" color="grey">{{item.text}}</span>
             
    
        
        <v-divider v-if="index+1<options.length" style="min-width:10px"></v-divider>
      </template>
    </div>
</template>
<script>
  import {VSlideYTransition} from 'vuetify/lib/components/transitions'
  export default {
    components: {
      VSlideYTransition
    },
    props: {
      options: {
        type: Array,
        required: true
      },
      selected: {
        type: String,        
      },      
    },
    data:()=>({
      selectIndex:0,
    }),
    mounted() {      
      this.selectIndex = this.options.findIndex(item=>item.value===this.selected)
    },
    watch:{
      selected(val){
        this.selectIndex = this.options.findIndex(item=>item.value===this.selected)
      }
    },
    
  }
</script>