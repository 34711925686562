import request from '@/utils/request'

export  function login(data) {
  return request({
    url: '/session/auth/login',
    method: 'post',
    data
  })
}

export  function logout() {
  return request({
    url: '/session/auth/logout',
    method: 'post',
  })
}

export  function user_list(params) {
  return request({
    url: '/api/user/list',
    method: 'get',
    params,
  })
}
