<template>
  <v-app>
    <v-app-bar color="primary" dark app>
      <v-app-bar-nav-icon @click="$router.go(-1)">
        <v-icon>mdi-chevron-left </v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-title>销售订单</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn color="purple darken-3" fab small @click="isEditing = !isEditing">
        <v-icon v-if="isEditing">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-pencil
        </v-icon>
      </v-btn>
      <template v-slot:extension>
        <v-tabs v-model="tab"  app>
          <v-tabs-slider color="purple lighten-4"></v-tabs-slider>

          <v-tab key="order">详情</v-tab>
          <v-tab key="detail">订单行</v-tab>

        </v-tabs>
      </template>
    </v-app-bar>
    <v-main>
      <v-tabs-items v-model="tab">
        <v-tab-item key="order">

          <v-card elevation="4" outlined class="ma-4 pb-8">
            <v-card-title>
              订单号：{{form.name}}
            </v-card-title>
            <v-card-subtitle>
              <v-slide-y-transition>
                <StateBars :options="[{'text':'报价单','value':'draft'},
                {'text':'部门负责人','value':'bumen'},
                {'text':'销售订单','value':'sale'}]" :selected="form.state" v-if="form.state"/>
              </v-slide-y-transition>
            </v-card-subtitle>
            <v-card-text>              
              <v-form ref="form" lazy-validation>
                <ProjectList :disabled="!isEditing" :project_id="form.project_id" :project_name="form.project_name"
                  @projectChange="projectChange" />
                <v-text-field v-model="form.code" label="项目编码" :disabled="!isEditing" readonly />
                <v-text-field v-model="form.fuzeren_name" label="项目经理" :disabled="!isEditing" readonly />
                <UserList :disabled="!isEditing" label="客服" :user_id="form.kefu_id" :user_name="form.kefu_name"
                  @userChange="kefuChange" />
                <v-text-field label="区域" v-model="form.area" :disabled="!isEditing" readonly />
                <v-text-field v-model="form.street" label="地址" :disabled="!isEditing" readonly />
                <v-row>
                  <v-col cols="6">
                    <SelectDate :pdate="form.date_start_jc" :disabled="!isEditing" label="进场开始日期" field="date_start_jc"
                      @change="dateChange" />
                  </v-col>
                  <v-col cols="6">
                    <SelectDate :pdate="form.date_end_jc" :disabled="!isEditing" label="进场结束日期" field="date_end_jc"
                      @change="dateChange" />
                  </v-col>
                  <v-col cols="6">
                    <SelectDate :pdate="form.date_start_cp" :disabled="!isEditing" label="彩排开始日期" field="date_start_cp"
                      @change="dateChange" />
                  </v-col>
                  <v-col cols="6">
                    <SelectDate :pdate="form.date_end_cp" :disabled="!isEditing" label="彩排结束日期" field="date_end_cp"
                      @change="dateChange" />
                  </v-col>
                  <v-col cols="6">
                    <SelectDate :pdate="form.date_start_hd" :disabled="!isEditing" label="活动开始日期" field="date_start_hd"
                      @change="dateChange" />
                  </v-col>
                  <v-col cols="6">
                    <SelectDate :pdate="form.date_end_hd" :disabled="!isEditing" label="活动结束日期" field="date_end_hd"
                      @change="dateChange" />
                  </v-col>
                </v-row>

              </v-form>
            </v-card-text>
          </v-card>

        </v-tab-item>
        <v-tab-item key="detail">
          <v-row dense>
            <v-col cols="12">
              <v-card>
                
                
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-main>
  </v-app>
</template>
<script>
import ProjectList from '@/components/ProjectList.vue'
import UserList from '@/components/UserList.vue'
import SelectDate from '@/components/SelectDate.vue'
import StateBars from '@/components/StateBars.vue'
import { sale_order } from '@/api/sale'
export default {
  name: 'SaleDetail',

  components: {
    StateBars,
    ProjectList,
    UserList,
    SelectDate,
  },
  data() {
    return {
      hasSaved: false,
      isEditing: true,
      tab: null,

      menu: false,
      states: [
        {
          text: '报价单',
          disabled: false,
          href: '#',
        },
        {
          text: '部门负责人审批',
          disabled: false,
          href: '#',
        },
        {
          text: '销售订单',
          disabled: true,
          href: '#',
        },
      ],

      form: {
        'project_id': null,
        'project_name': '',
        'code': '',
        'fuzeren_id': null,
        'fuzeren_name': '',
        'kefu_id': null,
        'kefu_name': '',
        'date_start_jc': '',
        'date_end_jc': '',
        'date_start_cp': '',
        'date_end_cp': '',
        'date_start_hd': '',
        'date_end_hd': '',
        'country_id': null,
        'country_name': '',
        'state_id': null,
        'state_name': '',
        'city_id': null,
        'city_name': '',
        'district_id': null,
        'district_name': '',
        'street': '',
        'sf_date': '',
        'sf_jine': '',
        'area': '',
      },
      project: null,
    }
  },

  created() {
    const route = this.$route
    if (route.query.id) {
      this.fetchData(route.query.id)
      this.isEditing = false
    }
  },

  methods: {
    processData() {
      let country_name = this.form.country_name ? this.form.country_name : ''
      let state_name = this.form.state_name ? this.form.state_name : ''
      let city_name = this.form.city_name ? this.form.city_name : ''
      let district_name = this.form.district_name ? this.form.district_name : ''
      this.form.area = country_name + state_name + city_name + district_name
      this.form.kefu_id = this.form.kefu_id ? this.form.kefu_id : null
      this.form.kefu_name = this.form.kefu_name ? this.form.kefu_name : null
    },
    projectChange(project) {
      
      this.form.project_id = project.id
      this.form.project_name = project.name
      this.form.code = project.code
      this.form.fuzeren_id = project.user_id ? project.user_id : null
      this.form.fuzeren_name = project.user_name ? project.user_name : ''
      this.form.kefu_id = project.kefu_id ? project.kefu_id : null
      this.form.kefu_name = project.kefu_name ? project.kefu_name : ''
      this.form.country_id = project.country_id ? project.country_id : null
      this.form.country_name = project.country_name ? project.country_name : ''
      this.form.state_id = project.state_id ? project.state_id : null
      this.form.state_name = project.state_name ? project.state_name : ''
      this.form.city_id = project.city_id ? project.city_id : null
      this.form.city_name = project.city_name ? project.city_name : ''
      this.form.district_id = project.district_id ? project.district_id : null
      this.form.district_name = project.district_name ? project.district_name : ''
      this.form.street = project.street ? project.street : ''
      this.form.date_start_jc = project.date_start_jc ? project.date_start_jc : ''
      this.form.date_end_jc = project.date_end_jc ? project.date_end_jc : ''      
      this.form.date_start_cp = project.date_start_cp ? project.date_start_cp : ''
      this.form.date_end_cp = project.date_end_cp ? project.date_end_cp : ''
      this.form.date_start_hd = project.date_start_ks ? project.date_start_ks : ''
      this.form.date_end_hd = project.date_end_ks ? project.date_end_ks : ''
      this.processData()
    },
    kefuChange(user) {
      this.form.kefu_id = user.id
      this.form.kefu_name = user.name
    },
    dateChange(date, field) {
      this.form[field] = date
      //console.log(this.form)
    },
    async fetchData(id) {
      let res = await sale_order(id)
      this.form = res
      this.processData()
      //console.log(this.form)
    }
  },

}
</script>