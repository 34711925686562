<template>
  <v-bottom-navigation :value="value" color="primary" grow app>
    <v-btn>
      <span>功能</span>

      <v-icon>mdi-menu-open</v-icon>
    </v-btn>

    <v-btn>
      <span>任务</span>

      <v-icon>mdi-calendar-clock</v-icon>
    </v-btn>

    <v-btn>
      <span>我的</span>

      <v-icon>mdi-account-star</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  data: () => ({ value: 0 }),
}
</script>
