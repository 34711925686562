<template>
  <div class="hello">
    <StreamBarcodeReader
      @decode="(a, b, c) => onDecode(a, b, c)"
      @loaded="() => onLoaded()"
    ></StreamBarcodeReader>
    <ImageBarcodeReader
      @error="onError"
      @decode="onScannerDecode"
    ></ImageBarcodeReader>
    Input Value: {{ text || "Nothing" }}
  </div>
</template>

<script>
import { StreamBarcodeReader,ImageBarcodeReader } from "vue-barcode-reader";

export default {
  name: "Test",
  components: {
    StreamBarcodeReader,
    ImageBarcodeReader,
  },
  data() {
    return {
      text: "",
      id: null,
    };
  },
  props: {
    msg: String,
  },
  methods: {
    onDecode(a, b, c) {
      console.log(a, b, c);
      this.text = a;
      if (this.id) clearTimeout(this.id);
      this.id = setTimeout(() => {
        if (this.text === a) {
          this.text = "";
        }
      }, 5000);
    },
    onLoaded() {
      console.log("load");
    },
    onError(err) {
      console.log(err);
    },
    onScannerDecode(result) {
      this.text = result;
    },
  },
};
</script>
<style scoped>
</style>
